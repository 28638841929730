// pages/Contact.js
import React from 'react';

export default function Contact() {
  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      <p>문의하기 페이지입니다.</p>
    </div>
  );
}